import { BankTransferType, Country } from "@/app/utils/common/constants";

export function bankTransferType(country) : string {
  if (country === Country.USA) {
    return BankTransferType.ACH_DEBIT;
  } else if (country === Country.GBR) {
    return BankTransferType.BACS_DEBIT;
  } else {
    return BankTransferType.SEPA_DEBIT;
  }
}