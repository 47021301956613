import { format, length, regex, strictInt } from "@/app/validators/api/customValidations";
import { Country } from "@/app/utils/common/constants";

export const organisationRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    [Country.GBR]: {
        length: length(8,8)
    },
    [Country.ESP]: {
        regex: regex("^[a-zA-Z][a-zA-Z0-9]{8}$", "start with 1 letter followed by 8 alphanumeric characters")
    }
}

export const organisationTaxId = {
    [Country.DEU]: {
        length: length(10,11), "strictInt": strictInt()
    },
    [Country.GBR]: {
        length: length(10,10), "strictInt": strictInt()
    },
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt()
    }
}

export const organisationVatNumber = {
    [Country.FRA]: {
        regex: regex("^FR\\d{11}$", "start with FR followed by 11 digits")
    },
    [Country.DEU]: {
        regex: regex("^DE\\d{9}$", "start with DE followed by 9 digits")
    },
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    },
    [Country.ESP]: {
        regex: regex("^ES[a-zA-Z0-9]{9}$", "start with ES followed by 9 alphanumeric characters")
    },
}

export const soleProprietorRegistrationNumber = {
    [Country.FRA]: {
        length: length(14,14), "strictInt": strictInt()
    },
    [Country.DEU]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt()
    }
}

export const soleProprietorTaxId = {
    [Country.USA]: {
        length: length(9,9), "strictInt": strictInt()
    }
}

export const soleProprietorVatNumber = {
    [Country.GBR]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    }
}

export const accountNumber = {
    [Country.GBR]: {
        length: length(8,8), "strictInt": strictInt()
    },
    [Country.USA]: {
        length: length(3,17), "strictInt": strictInt()
    }
}

export const bankCode = {
    [Country.USA]: {
        length: length(9,10), "strictInt": strictInt()
    }
}

export const iban = {
    [Country.FRA]: {
        regex: regex("^FR\\d{25}$", "start with FR followed by 25 digits")
    },
    [Country.DEU]: {
        regex: regex("^DE\\d{20}$", "start with DE followed by 20 digits")
    },
    [Country.ESP]: {
        regex: regex("^^ES\\d{2}[0-9]{20}$", "start with ES followed by 22 digits")
    },
}

export const sortCode = {
    [Country.GBR]: {
        length: length(6,6), "strictInt": strictInt()
    }
}
