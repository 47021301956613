import {ConfigurationResponseDto, FetchSessionResponseDto, MerchantResponseDto} from "@/api/paymentApi";
import CbError from "@/app/utils/common/core/cb-error";
import {navigateTo} from "@/router";
import store from '@/store'
import {RouteConstants} from "@/router/routeConstants";

export const storeMerchantContextForAdmin = (query) => {
    if (!(query.applicationName && query.merchantId && query.merchantName)) {
        navigateTo(RouteConstants.ADMIN, false, null)
        return false;
    }
    store.dispatch("app/updateAppState", {key: 'applicationName', value: query.applicationName})
    store.dispatch("app/updateAppState", {key: 'merchantId', value: query.merchantId})
    store.dispatch("app/updateAppState", {key: 'merchantName', value: query.merchantName})
    store.dispatch("app/updateAppState", {key: 'adminMode', value: true})
    return true;
}

export const storeAppDetails = (sessionResponse: FetchSessionResponseDto) => {
    store.dispatch("app/updateAppState", {key: "callbackUrl", value: sessionResponse.callback_url})
    store.dispatch("app/updateAppState", {key: "merchantId", value: sessionResponse.merchant_id})
    store.dispatch("app/updateAppState", {key: "externalId", value: sessionResponse.external_id})
    store.dispatch("app/updateAppState", {key: "merchantName", value: sessionResponse.merchant_name})
    store.dispatch("app/updateAppState", {key: "draftMerchantId", value: sessionResponse.onboarding_draft_id})
    store.dispatch("app/updateAppState", {key: "sessionPayfac", value: sessionResponse.payfac})
    store.dispatch("app/updateAppState", {key: "cardPricing", value: sessionResponse.additional_info?.card_pricing})
    store.dispatch("app/updateAppState", {key: "ACHPricing", value: sessionResponse.additional_info?.ach_pricing})
    store.dispatch("app/updateAppState", {key: "BACSPricing", value: sessionResponse.additional_info?.bacs_pricing})
    store.dispatch("app/updateAppState", {key: "SEPAPricing", value: sessionResponse.additional_info?.sepa_pricing})
    return sessionResponse;
}

export const storeMerchantDetails = (merchantResponse: MerchantResponseDto) => {
    store.dispatch("businessProfile/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("businessAddress/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("businessStructure/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("stakeholdersDetails/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("additionalUnderwritingData/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("bankAccountForPayouts/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("agreementAcceptance/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("merchantPayfacDetails/copyFromMerchantApiResponse", merchantResponse)
    store.dispatch("app/updateAppState", {key: "merchantCreatedAt", value: merchantResponse.created_at})
    store.dispatch("app/updateAppState", {key: "cbSiteName", value: merchantResponse.tags?.cb_site_name})
    store.dispatch("app/updateAppState", {key: "merchantPayfac", value: merchantResponse.payfac})

    const isSandbox: boolean = (merchantResponse.tags?.cb_site_name?.endsWith("-test")) || false;
    store.dispatch("app/updateAppState", {key: "isSandbox", value: isSandbox})
    store.dispatch("onboarding/validateAllFields")
    store.dispatch("app/updateAppState", {key: "merchantAlreadyStored", value: true})
}

export const storeMerchantConfiguration = (configurationResponse: ConfigurationResponseDto) => {
    store.dispatch("app/storeTimezone", configurationResponse.timezone)
    store.dispatch("app/updateAppState", {key: "rollingReserveConfig", value: configurationResponse.rolling_reserve_config})
    store.dispatch("app/updateAppState", {key: "merchantCurrency", value: configurationResponse.currency})
}

export const storeErrorDetails = (error: CbError) => {
    store.dispatch("businessProfile/copyFromFieldErrorsResponse", error.source?.sub_errors)
    store.dispatch("businessAddress/copyFromFieldErrorsResponse", error.source?.sub_errors)
    store.dispatch("businessStructure/copyFromFieldErrorsResponse", error.source?.sub_errors)
    store.dispatch("additionalUnderwritingData/copyFromFieldErrorsResponse", error.source?.sub_errors)
    store.dispatch("bankAccountForPayouts/copyFromFieldErrorsResponse", error.source?.sub_errors)
    store.dispatch("agreementAcceptance/copyFromFieldErrorsResponse", error.source?.sub_errors)
}

export const getStore = () => store;

