/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import axios from "axios";
import {IANAZone} from 'luxon';
import jwt_decode from "jwt-decode";
import {FinixErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper/finix/error-code-mapper";
import {AdyenErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper/adyen/error-code-mapper";
import cbStorage from "@/app/utils/common/core/cb-storage";
import {CbStorage} from "@/app/utils/common/constants";

interface JwtToken {
    application_type: string;
    exp: number;
    iat: number;
    iss: string;
    sub: string;
}

export const appDefaultState = {
    adminMode: false,
    merchantId: null,
    applicationId: null,
    applicationName: null,
    merchantName: null,
    merchantCreatedAt: null,
    merchants: [],
    recentlySelectedMerchants: [],
    externalId: null,
    draftMerchantId: null,
    autoSavedDraftMerchantId: null,
    callbackUrl: null,
    clientIpAddress: null,
    timezone: null,
    merchantAlreadyStored: false,
    jwtToken: null,
    merchantPayfac: null,
    sessionPayfac: null,
    cardPricing: null,
    ACHPricing: null,
    BACSPricing: null,
    SEPAPricing: null,
    rollingReserveConfig: null,
    cbSiteName: null,
    isSandbox: false,
    merchantCurrency: null
};

const getters = {
    getClientIpAddress: s => s.clientIpAddress,
    isAdminMode: s => s.adminMode,
    getMerchantId: s => s.merchantId,
    getMerchants: s => s.merchants,
    getApplicationId: s => s.applicationId,
    getRecentlySelectedMerchants: s => s.recentlySelectedMerchants,
    getApplicationName: s => s.applicationName,
    getMerchantName: s => s.merchantName,
    getMerchantCreatedAt: s => s.merchantCreatedAt,
    getExternalId: s => s.externalId,
    getDraftMerchantId: s => s.draftMerchantId,
    getAutoSavedDraftMerchantId: s => s.autoSavedDraftMerchantId,
    getCallbackUrl: s => s.callbackUrl,
    getTimezone: s => s.timezone,
    isMerchantAlreadyStored: s => s.merchantAlreadyStored,
    getJwtToken: s => s.jwtToken,
    getCardPricing: s => s.cardPricing,
    getACHPricing: s => s.ACHPricing,
    getBACSPricing: s => s.BACSPricing,
    getSEPAPricing: s => s.SEPAPricing,
    getRollingReserveConfig: s => s.rollingReserveConfig,
    //This is merchant payfac
    getActivePayfac: s => s.merchantPayfac,
    //This is session payfac
    getSessionPayfac: s => s.sessionPayfac,
    getPayfacErrorCodeMapper: s => {
        switch(s.sessionPayfac){
            case "FINIX":
                return new FinixErrorCodeMapper();
            case "ADYEN":
                return new AdyenErrorCodeMapper();
        }
    },
    hideOnboardingPage: () => {
        //TODO: to be handled for prod-eu after testing
        if (process.env.VUE_APP_BUILD_ENV !== "prod") {
            return false;
        }

        if (cbStorage.getItem(CbStorage.JwtToken)){
            // @ts-ignore
            const decoded = jwt_decode<JwtToken>(cbStorage.getItem(CbStorage.JwtToken));
            return !decoded.application_type || decoded.application_type === "sandbox";
        }
    },
    getCbSiteName: s => s.cbSiteName,
    getIsSandbox: s => s.isSandbox,
    getMerchantCurrency: s => s.merchantCurrency
}

const IPIFY = 'https://api.ipify.org';

const actions = {
    async storeTimezone({commit, state}, timezone : string) {
        if (state.timezone === null || state.timezone === undefined) {
            const zone = new IANAZone(timezone);
            if (zone.isValid) {
                commit('updateAppState', {key: 'timezone', value: zone});
            } else {
                commit('updateAppState', {key: 'timezone', value: new IANAZone('UTC')});
            }
        }
    },
    updateAppState({commit}, {key, value}) {
        commit('updateAppState', {key, value})
    },
    async storeClientIpAddress({commit}) {
        const ipAddress = (await axios.get(IPIFY)).data
        commit('updateAppState', {key: 'clientIpAddress', value: ipAddress})
    },
};

const mutations = {
    updateAppState(state, {key, value}) {
        state[key] = value
    },
};

export const app = {
    namespaced: true,
    state: appDefaultState,
    getters,
    actions,
    mutations,
};