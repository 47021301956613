/* eslint-disable */
const validators = {
	"createMerchant": {
		"first_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"last_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"title": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			},
			"allow_blank": true
		},
		"principal_percentage_ownership": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"tax_info.tax_id": {
			"allow_blank": true
		},
		"tax_info.registration_number": {
			"allow_blank": true
		},
		"tax_info.vat_number": {
			"allow_blank": true
		},
		"dob": {
			"allow_blank": true,
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"phone": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 120
			}
		},
		"business_info.doing_business_as": {
			"allow_blank": true
		},
		"business_info.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL_SOLE_PROPRIETOR",
					"CORPORATION",
					"LIMITED_LIABILITY_COMPANY",
					"PARTNERSHIP",
					"ASSOCIATIONS_AND_TRUSTS",
					"TAX_EXEMPT",
					"INTERNATIONAL",
					"GOVERNMENT_ENTITY"
				]
			}
		},
		"business_info.tax_info.tax_id": {
			"allow_blank": true
		},
		"business_info.tax_info.registration_number": {
			"allow_blank": true
		},
		"business_info.tax_info.vat_number": {
			"allow_blank": true
		},
		"business_info.url": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			}
		},
		"business_info.description": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 200
			}
		},
		"business_info.mcc": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"business_info.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"business_info.incorporation_date": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"business_info.address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"business_info.address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"business_info.address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"business_info.address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"business_info.address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"business_info.address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.ownership_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"PRIVATE",
					"PUBLIC"
				]
			}
		},
		"processing_info.statement_descriptor": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 20
			}
		},
		"processing_info.merchant_agreement_accepted": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_ip_address": {
			"allow_blank": true
		},
		"processing_info.merchant_agreement_timestamp": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_user_agent": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"additional_beneficiaries.*.id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.id_at_payfac": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.first_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.last_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.title": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			}
		},
		"additional_beneficiaries.*.principal_percentage_ownership": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_beneficiaries.*.tax_info.tax_id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.registration_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.vat_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.dob": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"additional_beneficiaries.*.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.email": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"additional_beneficiaries.*.business_owner_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL",
					"ENTITY"
				]
			}
		},
		"additional_beneficiaries.*.business_name": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 120
			}
		},
		"additional_beneficiaries.*.personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"additional_beneficiaries.*.personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"additional_beneficiaries.*.personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"additional_beneficiaries.*.personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"additional_beneficiaries.*.created_at": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.updated_at": {
			"allow_blank": true
		},
		"auto_save": {
			"allow_blank": true
		},
		"payment_instrument.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"bank_account",
					"card"
				]
			}
		},
		"payment_instrument.bank_account.id": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 40
			}
		},
		"payment_instrument.bank_account.account_number": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.account_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CHECKING",
					"SAVINGS"
				]
			}
		},
		"payment_instrument.bank_account.bank_code": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.sort_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 6,
				"maximum": 6
			}
		},
		"payment_instrument.bank_account.iban": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ACH_DEBIT",
					"BACS_DEBIT",
					"SEPA_DEBIT"
				]
			}
		},
		"payment_instrument.bank_account.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.id": {
			"allow_blank": true
		},
		"payment_instrument.card.expiration_year": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}$"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 4
			}
		},
		"payment_instrument.card.number": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d+$"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 13,
				"maximum": 19
			}
		},
		"payment_instrument.card.expiration_month": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 12
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"payment_instrument.card.card_holder_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"payment_instrument.card.card_holder_address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"payment_instrument.card.card_holder_address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.security_code": {
			"presence": {
				"message": "validation_error.presence"
			}
		}
	},
	"createSandboxMerchant": {
		"site_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"country": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true
		}
	},
	"updateMerchant": {
		"first_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"last_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"title": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			},
			"allow_blank": true
		},
		"principal_percentage_ownership": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"tax_info.tax_id": {
			"allow_blank": true
		},
		"tax_info.registration_number": {
			"allow_blank": true
		},
		"tax_info.vat_number": {
			"allow_blank": true
		},
		"dob": {
			"allow_blank": true,
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"phone": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 120
			}
		},
		"business_info.doing_business_as": {
			"allow_blank": true
		},
		"business_info.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL_SOLE_PROPRIETOR",
					"CORPORATION",
					"LIMITED_LIABILITY_COMPANY",
					"PARTNERSHIP",
					"ASSOCIATIONS_AND_TRUSTS",
					"TAX_EXEMPT",
					"INTERNATIONAL",
					"GOVERNMENT_ENTITY"
				]
			}
		},
		"business_info.tax_info.tax_id": {
			"allow_blank": true
		},
		"business_info.tax_info.registration_number": {
			"allow_blank": true
		},
		"business_info.tax_info.vat_number": {
			"allow_blank": true
		},
		"business_info.url": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			}
		},
		"business_info.description": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 200
			}
		},
		"business_info.mcc": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"business_info.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"business_info.incorporation_date": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"business_info.address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"business_info.address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"business_info.address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"business_info.address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"business_info.address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"business_info.address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.ownership_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"PRIVATE",
					"PUBLIC"
				]
			}
		},
		"processing_info.statement_descriptor": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 20
			}
		},
		"processing_info.merchant_agreement_accepted": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_ip_address": {
			"allow_blank": true
		},
		"processing_info.merchant_agreement_timestamp": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_user_agent": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"additional_beneficiaries.*.id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.id_at_payfac": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.first_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.last_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.title": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			}
		},
		"additional_beneficiaries.*.principal_percentage_ownership": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_beneficiaries.*.tax_info.tax_id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.registration_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.vat_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.dob": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"additional_beneficiaries.*.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.email": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"additional_beneficiaries.*.business_owner_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL",
					"ENTITY"
				]
			}
		},
		"additional_beneficiaries.*.business_name": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 120
			}
		},
		"additional_beneficiaries.*.personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"additional_beneficiaries.*.personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"additional_beneficiaries.*.personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"additional_beneficiaries.*.personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"additional_beneficiaries.*.created_at": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.updated_at": {
			"allow_blank": true
		},
		"auto_save": {
			"allow_blank": true
		},
		"payment_instrument.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"bank_account",
					"card"
				]
			}
		},
		"payment_instrument.bank_account.id": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 40
			}
		},
		"payment_instrument.bank_account.account_number": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.account_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CHECKING",
					"SAVINGS"
				]
			}
		},
		"payment_instrument.bank_account.bank_code": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.sort_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 6,
				"maximum": 6
			}
		},
		"payment_instrument.bank_account.iban": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ACH_DEBIT",
					"BACS_DEBIT",
					"SEPA_DEBIT"
				]
			}
		},
		"payment_instrument.bank_account.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.id": {
			"allow_blank": true
		},
		"payment_instrument.card.expiration_year": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}$"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 4
			}
		},
		"payment_instrument.card.number": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d+$"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 13,
				"maximum": 19
			}
		},
		"payment_instrument.card.expiration_month": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 12
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"payment_instrument.card.card_holder_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"payment_instrument.card.card_holder_address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"payment_instrument.card.card_holder_address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.security_code": {
			"presence": {
				"message": "validation_error.presence"
			}
		}
	},
	"getPreSignedURLForFileUpload": {
		"resource_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"TRANSACTION",
					"SALE",
					"REFUND",
					"DISPUTE",
					"DEPOSIT",
					"MERCHANT",
					"COMPLIANCE_FORM",
					"AUTHORIZATION"
				]
			}
		},
		"resource_id": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"file_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		}
	},
	"asyncFileUpload": {},
	"uploadFile": {},
	"UpdateMerchantConfiguration": {
		"card_pricing.pricing_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"BLENDED_PRICING_FLAT_RATE"
				]
			}
		},
		"card_pricing.fixed_rate": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.cross_border_basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.cross_border_basis_points_uk": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.cross_border_basis_points_eea": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.forex_basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card_pricing.three_ds_fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"ach_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"bacs_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"sepa_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"allow_refund_for_insufficient_balance": {
			"allow_blank": true
		},
		"rolling_reserve_config.reserve_window": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"rolling_reserve_config.daily_settlement_volume_percentage": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"rolling_reserve_config.review_period": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"upfront_reserve_config.reserve_window": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"upfront_reserve_config.amount_to_be_held": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"upfront_reserve_config.review_period": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"negative_balance_recovery_config.auto_recovery_days": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"min": "validation_error.number.range.min"
				},
				"minimum": 1
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"timezone": {
			"allow_blank": true
		},
		"currency": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"AED",
					"AMD",
					"ANG",
					"AOA",
					"ARS",
					"AUD",
					"AWG",
					"AZN",
					"BAM",
					"BBD",
					"BDT",
					"BGN",
					"BMD",
					"BND",
					"BOB",
					"BRL",
					"BSD",
					"BWP",
					"BYN",
					"BZD",
					"CAD",
					"CHF",
					"CLP",
					"CNY",
					"COP",
					"CRC",
					"CZK",
					"DKK",
					"DOP",
					"DZD",
					"EGP",
					"ETB",
					"EUR",
					"FJD",
					"FKP",
					"GBP",
					"GEL",
					"GHS",
					"GIP",
					"GMD",
					"GTQ",
					"GYD",
					"HKD",
					"HNL",
					"HUF",
					"ILS",
					"INR",
					"ISK",
					"JMD",
					"KES",
					"KGS",
					"KYD",
					"KZT",
					"LAK",
					"LBP",
					"LKR",
					"MAD",
					"MDL",
					"MKD",
					"MMK",
					"MOP",
					"MUR",
					"MVR",
					"MWK",
					"MXN",
					"MYR",
					"MZN",
					"NAD",
					"NGN",
					"NIO",
					"NOK",
					"NPR",
					"NZD",
					"PAB",
					"PEN",
					"PGK",
					"PHP",
					"PKR",
					"PLN",
					"QAR",
					"RON",
					"RSD",
					"SAR",
					"SBD",
					"SEK",
					"SGD",
					"SHP",
					"SOS",
					"SRD",
					"THB",
					"TOP",
					"TRY",
					"TTD",
					"TWD",
					"TZS",
					"UAH",
					"USD",
					"UYU",
					"UZS",
					"WST",
					"XCD",
					"ZAR",
					"ZMW"
				]
			}
		}
	},
	"createConsumer": {
		"first_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"last_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"phone": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		}
	},
	"createMerchantPaymentInstrument": {
		"type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"bank_account",
					"card"
				]
			}
		},
		"bank_account.id": {
			"allow_blank": true
		},
		"bank_account.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 40
			}
		},
		"bank_account.account_number": {
			"allow_blank": true
		},
		"bank_account.account_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CHECKING",
					"SAVINGS"
				]
			}
		},
		"bank_account.bank_code": {
			"allow_blank": true
		},
		"bank_account.sort_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 6,
				"maximum": 6
			}
		},
		"bank_account.iban": {
			"allow_blank": true
		},
		"bank_account.type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ACH_DEBIT",
					"BACS_DEBIT",
					"SEPA_DEBIT"
				]
			}
		},
		"bank_account.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"card.id": {
			"allow_blank": true
		},
		"card.expiration_year": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}$"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 4
			}
		},
		"card.number": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d+$"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 13,
				"maximum": 19
			}
		},
		"card.expiration_month": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 12
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card.card_holder_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"card.card_holder_address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"card.card_holder_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"card.card_holder_address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"card.card_holder_address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"card.card_holder_address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"card.card_holder_address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"card.security_code": {
			"presence": {
				"message": "validation_error.presence"
			}
		}
	},
	"createConsumerPaymentInstrument": {
		"type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"bank_account",
					"card"
				]
			}
		},
		"bank_account.id": {
			"allow_blank": true
		},
		"bank_account.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 40
			}
		},
		"bank_account.account_number": {
			"allow_blank": true
		},
		"bank_account.account_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CHECKING",
					"SAVINGS"
				]
			}
		},
		"bank_account.bank_code": {
			"allow_blank": true
		},
		"bank_account.sort_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 6,
				"maximum": 6
			}
		},
		"bank_account.iban": {
			"allow_blank": true
		},
		"bank_account.type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ACH_DEBIT",
					"BACS_DEBIT",
					"SEPA_DEBIT"
				]
			}
		},
		"bank_account.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"card.id": {
			"allow_blank": true
		},
		"card.expiration_year": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}$"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 4
			}
		},
		"card.number": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d+$"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 13,
				"maximum": 19
			}
		},
		"card.expiration_month": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 12
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"card.card_holder_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"card.card_holder_address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"card.card_holder_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"card.card_holder_address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"card.card_holder_address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"card.card_holder_address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"card.card_holder_address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"card.security_code": {
			"presence": {
				"message": "validation_error.presence"
			}
		}
	},
	"addFundToBalanceAccount": {
		"merchant_payment_instrument_id": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"split": {
			"presence": {
				"message": "validation_error.presence"
			},
			"childValidations": {
				"balance_account_type": {
					"allow_blank": true,
					"enum": {
						"message": "validation_error.enum.invalid",
						"values": [
							"CURRENT",
							"REFUND_RESERVE",
							"CHARGEBACK_RESERVE",
							"ROLLING_RESERVE",
							"UPFRONT_RESERVE"
						]
					}
				},
				"amount": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				}
			}
		}
	},
	"createSale": {
		"id": {
			"allow_blank": true
		},
		"consumer_id": {
			"allow_blank": true
		},
		"merchant_id": {
			"allow_blank": true
		},
		"payment_instrument_id": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"transaction_id": {
			"allow_blank": true
		},
		"currency": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"AED",
					"AMD",
					"ANG",
					"AOA",
					"ARS",
					"AUD",
					"AWG",
					"AZN",
					"BAM",
					"BBD",
					"BDT",
					"BGN",
					"BMD",
					"BND",
					"BOB",
					"BRL",
					"BSD",
					"BWP",
					"BYN",
					"BZD",
					"CAD",
					"CHF",
					"CLP",
					"CNY",
					"COP",
					"CRC",
					"CZK",
					"DKK",
					"DOP",
					"DZD",
					"EGP",
					"ETB",
					"EUR",
					"FJD",
					"FKP",
					"GBP",
					"GEL",
					"GHS",
					"GIP",
					"GMD",
					"GTQ",
					"GYD",
					"HKD",
					"HNL",
					"HUF",
					"ILS",
					"INR",
					"ISK",
					"JMD",
					"KES",
					"KGS",
					"KYD",
					"KZT",
					"LAK",
					"LBP",
					"LKR",
					"MAD",
					"MDL",
					"MKD",
					"MMK",
					"MOP",
					"MUR",
					"MVR",
					"MWK",
					"MXN",
					"MYR",
					"MZN",
					"NAD",
					"NGN",
					"NIO",
					"NOK",
					"NPR",
					"NZD",
					"PAB",
					"PEN",
					"PGK",
					"PHP",
					"PKR",
					"PLN",
					"QAR",
					"RON",
					"RSD",
					"SAR",
					"SBD",
					"SEK",
					"SGD",
					"SHP",
					"SOS",
					"SRD",
					"THB",
					"TOP",
					"TRY",
					"TTD",
					"TWD",
					"TZS",
					"UAH",
					"USD",
					"UYU",
					"UZS",
					"WST",
					"XCD",
					"ZAR",
					"ZMW"
				]
			}
		},
		"amount": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"status": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CREATED",
					"INITIATED",
					"SUCCEEDED",
					"FAILED",
					"CANCELED",
					"RETURNED"
				]
			}
		},
		"error_reason": {
			"allow_blank": true
		},
		"error_code": {
			"allow_blank": true
		},
		"fraud_session_id": {
			"allow_blank": true
		},
		"created_at": {
			"allow_blank": true
		},
		"updated_at": {
			"allow_blank": true
		}
	},
	"createTransaction": {
		"consumer_id": {
			"allow_blank": true
		},
		"consumer.first_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"consumer.last_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"consumer.phone": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"consumer.email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"consumer.address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"consumer.address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"consumer.address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"consumer.address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"consumer.address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"consumer.address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument_id": {
			"allow_blank": true
		},
		"payment_instrument.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"bank_account",
					"card"
				]
			}
		},
		"payment_instrument.bank_account.id": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 40
			}
		},
		"payment_instrument.bank_account.account_number": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.account_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CHECKING",
					"SAVINGS"
				]
			}
		},
		"payment_instrument.bank_account.bank_code": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.sort_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 6,
				"maximum": 6
			}
		},
		"payment_instrument.bank_account.iban": {
			"allow_blank": true
		},
		"payment_instrument.bank_account.type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ACH_DEBIT",
					"BACS_DEBIT",
					"SEPA_DEBIT"
				]
			}
		},
		"payment_instrument.bank_account.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.id": {
			"allow_blank": true
		},
		"payment_instrument.card.expiration_year": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}$"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 4
			}
		},
		"payment_instrument.card.number": {
			"presence": {
				"message": "validation_error.presence"
			},
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d+$"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 13,
				"maximum": 19
			}
		},
		"payment_instrument.card.expiration_month": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 12
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"payment_instrument.card.card_holder_name": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"payment_instrument.card.card_holder_address.line1": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.city": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.state_code": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"payment_instrument.card.card_holder_address.postal_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			},
			"allow_blank": true
		},
		"payment_instrument.card.card_holder_address.country": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"payment_instrument.card.security_code": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"amount": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"currency": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"AED",
					"AMD",
					"ANG",
					"AOA",
					"ARS",
					"AUD",
					"AWG",
					"AZN",
					"BAM",
					"BBD",
					"BDT",
					"BGN",
					"BMD",
					"BND",
					"BOB",
					"BRL",
					"BSD",
					"BWP",
					"BYN",
					"BZD",
					"CAD",
					"CHF",
					"CLP",
					"CNY",
					"COP",
					"CRC",
					"CZK",
					"DKK",
					"DOP",
					"DZD",
					"EGP",
					"ETB",
					"EUR",
					"FJD",
					"FKP",
					"GBP",
					"GEL",
					"GHS",
					"GIP",
					"GMD",
					"GTQ",
					"GYD",
					"HKD",
					"HNL",
					"HUF",
					"ILS",
					"INR",
					"ISK",
					"JMD",
					"KES",
					"KGS",
					"KYD",
					"KZT",
					"LAK",
					"LBP",
					"LKR",
					"MAD",
					"MDL",
					"MKD",
					"MMK",
					"MOP",
					"MUR",
					"MVR",
					"MWK",
					"MXN",
					"MYR",
					"MZN",
					"NAD",
					"NGN",
					"NIO",
					"NOK",
					"NPR",
					"NZD",
					"PAB",
					"PEN",
					"PGK",
					"PHP",
					"PKR",
					"PLN",
					"QAR",
					"RON",
					"RSD",
					"SAR",
					"SBD",
					"SEK",
					"SGD",
					"SHP",
					"SOS",
					"SRD",
					"THB",
					"TOP",
					"TRY",
					"TTD",
					"TWD",
					"TZS",
					"UAH",
					"USD",
					"UYU",
					"UZS",
					"WST",
					"XCD",
					"ZAR",
					"ZMW"
				]
			}
		},
		"fraud_session_id": {
			"allow_blank": true
		},
		"additional_purchase_data.customer_reference": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"additional_purchase_data.total_tax_amount": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_purchase_data.items_data": {
			"allow_blank": true,
			"childValidations": {
				"quantity": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max",
							"min": "validation_error.number.range.min"
						},
						"minimum": 0,
						"maximum": 9223372036854776000
					},
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"item_description": {
					"allow_blank": true
				},
				"commodity_code": {
					"length": {
						"messages": {
							"max": "validation_error.string.length.max"
						},
						"maximum": 12
					},
					"allow_blank": true
				},
				"amount_excluding_sales_tax": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"amount_including_sales_tax": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"cost_per_unit": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"item_discount_amount": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"merchant_product_code": {
					"allow_blank": true
				},
				"unit_of_measure": {
					"length": {
						"messages": {
							"max": "validation_error.string.length.max"
						},
						"maximum": 3
					},
					"allow_blank": true
				}
			}
		},
		"additional_3ds_data.shopper_email": {
			"allow_blank": true
		},
		"additional_3ds_data.shopper_IP": {
			"allow_blank": true
		},
		"additional_3ds_data.origin": {
			"allow_blank": true
		},
		"additional_3ds_data.return_url": {
			"allow_blank": true
		},
		"additional_3ds_data.mobile_phone.country_code": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 5
			},
			"allow_blank": true
		},
		"additional_3ds_data.mobile_phone.number": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"additional_3ds_data.browser_info.user_agent": {
			"allow_blank": true
		},
		"additional_3ds_data.browser_info.color_depth": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_3ds_data.browser_info.java_enabled": {
			"allow_blank": true
		},
		"additional_3ds_data.browser_info.language": {
			"allow_blank": true
		},
		"additional_3ds_data.browser_info.screen_height": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_3ds_data.browser_info.screen_width": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_3ds_data.browser_info.time_zone_offset": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_3ds_data.browser_info.accept_header": {
			"allow_blank": true
		},
		"initiating_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CIT",
					"MIT"
				]
			}
		}
	},
	"submitTransactionDetails": {
		"details": {
			"allow_blank": true
		}
	},
	"createAsyncSale": {
		"id": {
			"allow_blank": true
		},
		"merchant_id": {
			"allow_blank": true
		},
		"consumer_id": {
			"allow_blank": true
		},
		"payment_instrument_id": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"currency": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"AED",
					"AMD",
					"ANG",
					"AOA",
					"ARS",
					"AUD",
					"AWG",
					"AZN",
					"BAM",
					"BBD",
					"BDT",
					"BGN",
					"BMD",
					"BND",
					"BOB",
					"BRL",
					"BSD",
					"BWP",
					"BYN",
					"BZD",
					"CAD",
					"CHF",
					"CLP",
					"CNY",
					"COP",
					"CRC",
					"CZK",
					"DKK",
					"DOP",
					"DZD",
					"EGP",
					"ETB",
					"EUR",
					"FJD",
					"FKP",
					"GBP",
					"GEL",
					"GHS",
					"GIP",
					"GMD",
					"GTQ",
					"GYD",
					"HKD",
					"HNL",
					"HUF",
					"ILS",
					"INR",
					"ISK",
					"JMD",
					"KES",
					"KGS",
					"KYD",
					"KZT",
					"LAK",
					"LBP",
					"LKR",
					"MAD",
					"MDL",
					"MKD",
					"MMK",
					"MOP",
					"MUR",
					"MVR",
					"MWK",
					"MXN",
					"MYR",
					"MZN",
					"NAD",
					"NGN",
					"NIO",
					"NOK",
					"NPR",
					"NZD",
					"PAB",
					"PEN",
					"PGK",
					"PHP",
					"PKR",
					"PLN",
					"QAR",
					"RON",
					"RSD",
					"SAR",
					"SBD",
					"SEK",
					"SGD",
					"SHP",
					"SOS",
					"SRD",
					"THB",
					"TOP",
					"TRY",
					"TTD",
					"TWD",
					"TZS",
					"UAH",
					"USD",
					"UYU",
					"UZS",
					"WST",
					"XCD",
					"ZAR",
					"ZMW"
				]
			}
		},
		"state": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INITIATION_STARTED",
					"QUEUED_ACCEPTED",
					"QUEUED_FAILED",
					"QUEUED_SUCCEEDED",
					"INITIATION_AT_PAYFAC_FAILED",
					"PENDING",
					"VERIFICATION_PENDING",
					"SUCCESS",
					"FAILED",
					"CANCELED",
					"REFUNDED",
					"NOT_REFUNDED",
					"RETURNED"
				]
			}
		},
		"three_ds_status": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"AUTHENTICATION_FINISHED",
					"AUTHENTICATION_NOT_REQUIRED",
					"AUTHORISED",
					"CANCELLED",
					"CHALLENGE_SHOPPER",
					"ERROR",
					"IDENTIFY_SHOPPER",
					"PARTIALLY_AUTHORISED",
					"PENDING",
					"PRESENT_TO_SHOPPER",
					"RECEIVED",
					"REDIRECT_SHOPPER",
					"REFUSED",
					"SUCCESS"
				]
			}
		},
		"error_reason": {
			"allow_blank": true
		},
		"error_code": {
			"allow_blank": true
		},
		"fraud_session_id": {
			"allow_blank": true
		},
		"amount": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_purchase_data.customer_reference": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"additional_purchase_data.total_tax_amount": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max"
				},
				"maximum": 9223372036854776000
			},
			"allow_blank": true,
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_purchase_data.items_data": {
			"allow_blank": true,
			"childValidations": {
				"quantity": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max",
							"min": "validation_error.number.range.min"
						},
						"minimum": 0,
						"maximum": 9223372036854776000
					},
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"item_description": {
					"allow_blank": true
				},
				"commodity_code": {
					"length": {
						"messages": {
							"max": "validation_error.string.length.max"
						},
						"maximum": 12
					},
					"allow_blank": true
				},
				"amount_excluding_sales_tax": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"amount_including_sales_tax": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"cost_per_unit": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"item_discount_amount": {
					"range": {
						"messages": {
							"max": "validation_error.number.range.max"
						},
						"maximum": 9223372036854776000
					},
					"allow_blank": true,
					"strictInt": {
						"message": "validation_error.strict_int"
					}
				},
				"merchant_product_code": {
					"allow_blank": true
				},
				"unit_of_measure": {
					"length": {
						"messages": {
							"max": "validation_error.string.length.max"
						},
						"maximum": 3
					},
					"allow_blank": true
				}
			}
		},
		"created_at": {
			"allow_blank": true
		},
		"updated_at": {
			"allow_blank": true
		},
		"source_transaction_id": {
			"allow_blank": true
		},
		"type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"DEBIT",
					"REFUND",
					"ACH_RETURN",
					"PAYMENT_RETURN",
					"PAYMENT_REVERSAL",
					"CHARGEBACK_DEBIT",
					"CHARGEBACK_CREDIT",
					"CHARGEBACK_FEE",
					"RESERVE"
				]
			}
		},
		"tags.cb_transaction_id": {
			"allow_blank": true
		},
		"tags.cb_refunded_transaction_id": {
			"allow_blank": true
		},
		"tags.dispute_id": {
			"allow_blank": true
		},
		"tags.parent_transaction_id": {
			"allow_blank": true
		},
		"tags.is_dunning_attempt": {
			"allow_blank": true
		},
		"tags.cb_invoice_ids": {
			"allow_blank": true
		},
		"initiating_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CIT",
					"MIT"
				]
			}
		}
	},
	"createRefund": {
		"amount": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 1,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		}
	},
	"createReversals": {},
	"acceptTheDispute": {},
	"storeDisputeEvidenceDetails": {
		"customer_name": {
			"allow_blank": true
		},
		"customer_email": {
			"allow_blank": true
		},
		"type_of_purchase": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"PHYSICAL_GOODS",
					"DIGITAL_GOODS",
					"SERVICE"
				]
			}
		},
		"physical_goods_details.shipping_tracking_number": {
			"allow_blank": true
		},
		"physical_goods_details.delivery_partner": {
			"allow_blank": true
		},
		"physical_goods_details.purchase_details": {
			"allow_blank": true
		},
		"physical_goods_details.refund_policy_details": {
			"allow_blank": true
		},
		"digital_goods_details.purchase_date": {
			"allow_blank": true
		},
		"digital_goods_details.purchase_details": {
			"allow_blank": true
		},
		"digital_goods_details.refund_policy_details": {
			"allow_blank": true
		},
		"service_details.service_date": {
			"allow_blank": true
		},
		"service_details.purchase_date": {
			"allow_blank": true
		},
		"service_details.type_of_service": {
			"allow_blank": true
		},
		"service_details.description": {
			"allow_blank": true
		},
		"service_details.refund_policy_details": {
			"allow_blank": true
		},
		"file_evidences.files": {
			"allow_blank": true
		}
	},
	"createDisputeFileEvidence": {
		"files": {
			"allow_blank": true
		}
	},
	"createSession": {
		"merchant_name": {
			"allow_blank": true
		},
		"operation": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ONBOARDING",
					"STATUS",
					"LOGIN",
					"PAYFAC_INPUT"
				]
			}
		},
		"id": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"callback_url": {
			"allow_blank": true
		},
		"merchant_id": {
			"allow_blank": true
		},
		"redirect_path": {
			"allow_blank": true
		},
		"payfac": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"FINIX",
					"ADYEN"
				]
			}
		},
		"additional_info.card_pricing.pricing_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"BLENDED_PRICING_FLAT_RATE"
				]
			}
		},
		"additional_info.card_pricing.fixed_rate": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.cross_border_basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.cross_border_basis_points_uk": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.cross_border_basis_points_eea": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.forex_basis_points": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.card_pricing.three_ds_fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.ach_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.bacs_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.min_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.max_fee": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.fixed_rate": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.cross_border_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.sepa_pricing.forex_basis_points": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.allow_refund_for_insufficient_balance": {
			"allow_blank": true
		},
		"additional_info.rolling_reserve_config.reserve_window": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.rolling_reserve_config.daily_settlement_volume_percentage": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.rolling_reserve_config.review_period": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.upfront_reserve_config.reserve_window": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.upfront_reserve_config.amount_to_be_held": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 9223372036854776000
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.upfront_reserve_config.review_period": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 2147483647
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.negative_balance_recovery_config.auto_recovery_days": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"min": "validation_error.number.range.min"
				},
				"minimum": 1
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_info.cb_merchant_handle": {
			"allow_blank": true
		},
		"additional_info.cb_site_name": {
			"allow_blank": true
		},
		"additional_info.cb_merchant_email": {
			"allow_blank": true
		},
		"additional_info.timezone": {
			"allow_blank": true
		}
	},
	"saveOnboardingDraft": {
		"first_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"last_name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"title": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			},
			"allow_blank": true
		},
		"principal_percentage_ownership": {
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"tax_info.tax_id": {
			"allow_blank": true
		},
		"tax_info.registration_number": {
			"allow_blank": true
		},
		"tax_info.vat_number": {
			"allow_blank": true
		},
		"dob": {
			"allow_blank": true,
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"phone": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"email": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true,
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 120
			}
		},
		"business_info.doing_business_as": {
			"allow_blank": true
		},
		"business_info.type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL_SOLE_PROPRIETOR",
					"CORPORATION",
					"LIMITED_LIABILITY_COMPANY",
					"PARTNERSHIP",
					"ASSOCIATIONS_AND_TRUSTS",
					"TAX_EXEMPT",
					"INTERNATIONAL",
					"GOVERNMENT_ENTITY"
				]
			}
		},
		"business_info.tax_info.tax_id": {
			"allow_blank": true
		},
		"business_info.tax_info.registration_number": {
			"allow_blank": true
		},
		"business_info.tax_info.vat_number": {
			"allow_blank": true
		},
		"business_info.url": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			}
		},
		"business_info.description": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 200
			}
		},
		"business_info.mcc": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"business_info.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"business_info.incorporation_date": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"business_info.address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"business_info.address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"business_info.address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"business_info.address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"business_info.address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"business_info.address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"business_info.ownership_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"PRIVATE",
					"PUBLIC"
				]
			}
		},
		"processing_info.statement_descriptor": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 20
			}
		},
		"processing_info.merchant_agreement_accepted": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_ip_address": {
			"allow_blank": true
		},
		"processing_info.merchant_agreement_timestamp": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"processing_info.merchant_agreement_user_agent": {
			"presence": {
				"message": "validation_error.presence"
			}
		},
		"additional_beneficiaries.*.id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.id_at_payfac": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.first_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.last_name": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.title": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 60
			}
		},
		"additional_beneficiaries.*.principal_percentage_ownership": {
			"presence": {
				"message": "validation_error.presence"
			},
			"range": {
				"messages": {
					"max": "validation_error.number.range.max",
					"min": "validation_error.number.range.min"
				},
				"minimum": 0,
				"maximum": 100
			},
			"strictInt": {
				"message": "validation_error.strict_int"
			}
		},
		"additional_beneficiaries.*.tax_info.tax_id": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.registration_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.tax_info.vat_number": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.dob": {
			"presence": {
				"message": "validation_error.presence"
			},
			"dateFormat": {
				"message": "validation_error.date_format"
			}
		},
		"additional_beneficiaries.*.phone": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.email": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"email": {
				"message": "validation_error.email.main"
			}
		},
		"additional_beneficiaries.*.business_owner_type": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"INDIVIDUAL",
					"ENTITY"
				]
			}
		},
		"additional_beneficiaries.*.business_name": {
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 1,
				"maximum": 120
			}
		},
		"additional_beneficiaries.*.personal_address.line1": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			}
		},
		"additional_beneficiaries.*.personal_address.line2": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 150
			},
			"allow_blank": true
		},
		"additional_beneficiaries.*.personal_address.city": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 30
			}
		},
		"additional_beneficiaries.*.personal_address.state_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"min": "validation_error.string.length.min",
					"max": "validation_error.string.length.max"
				},
				"minimum": 2,
				"maximum": 50
			}
		},
		"additional_beneficiaries.*.personal_address.postal_code": {
			"presence": {
				"message": "validation_error.presence"
			},
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 10
			}
		},
		"additional_beneficiaries.*.personal_address.country": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ABW",
					"AFG",
					"AGO",
					"AIA",
					"ALA",
					"ALB",
					"AND",
					"ANT",
					"ARE",
					"ARG",
					"ARM",
					"ASM",
					"ATA",
					"ATF",
					"ATG",
					"AUS",
					"AUT",
					"AZE",
					"BDI",
					"BEL",
					"BEN",
					"BES",
					"BFA",
					"BGD",
					"BGR",
					"BHR",
					"BHS",
					"BIH",
					"BLM",
					"BLR",
					"BLZ",
					"BMU",
					"BOL",
					"BRA",
					"BRB",
					"BRN",
					"BTN",
					"BVT",
					"BWA",
					"CAF",
					"CAN",
					"CCK",
					"CHE",
					"CHL",
					"CHN",
					"CIV",
					"CMR",
					"COD",
					"COG",
					"COK",
					"COL",
					"COM",
					"CPV",
					"CRI",
					"CUB",
					"CUW",
					"CXR",
					"CYM",
					"CYP",
					"CZE",
					"DEU",
					"DJI",
					"DMA",
					"DNK",
					"DOM",
					"DZA",
					"EAC",
					"ECU",
					"EGY",
					"ERI",
					"ESH",
					"ESP",
					"EST",
					"ETH",
					"FIN",
					"FJI",
					"FLK",
					"FRA",
					"FRO",
					"FSM",
					"GAB",
					"GBR",
					"GEO",
					"GGY",
					"GHA",
					"GIB",
					"GIN",
					"GLP",
					"GMB",
					"GNB",
					"GNQ",
					"GRC",
					"GRD",
					"GRL",
					"GTM",
					"GUF",
					"GUM",
					"GUY",
					"HKG",
					"HMD",
					"HND",
					"HRV",
					"HTI",
					"HUN",
					"IC",
					"IDN",
					"IMN",
					"IND",
					"IOT",
					"IRL",
					"IRN",
					"IRQ",
					"ISL",
					"ISR",
					"ITA",
					"JAM",
					"JEY",
					"JOR",
					"JPN",
					"KAZ",
					"KEN",
					"KGZ",
					"KHM",
					"KIR",
					"KNA",
					"KOR",
					"KWT",
					"LAO",
					"LBN",
					"LBR",
					"LBY",
					"LCA",
					"LIE",
					"LKA",
					"LSO",
					"LTU",
					"LUX",
					"LVA",
					"MAC",
					"MAF",
					"MAR",
					"MCO",
					"MDA",
					"MDG",
					"MDV",
					"MEX",
					"MHL",
					"MKD",
					"MLI",
					"MLT",
					"MMR",
					"MNE",
					"MNG",
					"MNP",
					"MOZ",
					"MRT",
					"MSR",
					"MTQ",
					"MUS",
					"MWI",
					"MYS",
					"MYT",
					"NAM",
					"NCL",
					"NER",
					"NFK",
					"NGA",
					"NIC",
					"NIR",
					"NIU",
					"NLD",
					"NOR",
					"NPL",
					"NRU",
					"NZL",
					"OMN",
					"PAK",
					"PAN",
					"PCN",
					"PER",
					"PHL",
					"PLW",
					"PNG",
					"POL",
					"PRI",
					"PRK",
					"PRT",
					"PRY",
					"PSE",
					"PYF",
					"QAT",
					"REU",
					"ROU",
					"RUS",
					"RWA",
					"SAU",
					"SDN",
					"SEN",
					"SGP",
					"SGS",
					"SHN",
					"SJM",
					"SLB",
					"SLE",
					"SLV",
					"SMR",
					"SOM",
					"SPM",
					"SRB",
					"SSD",
					"STP",
					"SUR",
					"SVK",
					"SVN",
					"SWE",
					"SWZ",
					"SXM",
					"SYC",
					"SYR",
					"TCA",
					"TCD",
					"TGO",
					"THA",
					"TJK",
					"TKL",
					"TKM",
					"TLS",
					"TON",
					"TTO",
					"TUN",
					"TUR",
					"TUV",
					"TWN",
					"TZA",
					"UGA",
					"UKR",
					"UMI",
					"URY",
					"USA",
					"UZB",
					"VAT",
					"VCT",
					"VEN",
					"VGB",
					"VIR",
					"VNM",
					"VUT",
					"WLF",
					"WSM",
					"XKX",
					"YEM",
					"ZAF",
					"ZMB",
					"ZWE"
				]
			}
		},
		"additional_beneficiaries.*.created_at": {
			"allow_blank": true
		},
		"additional_beneficiaries.*.updated_at": {
			"allow_blank": true
		},
		"auto_save": {
			"allow_blank": true
		}
	},
	"createExport": {
		"resource_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"TRANSACTION",
					"SALE",
					"REFUND",
					"DISPUTE",
					"DEPOSIT",
					"MERCHANT",
					"COMPLIANCE_FORM",
					"AUTHORIZATION"
				]
			}
		},
		"transaction_filter.type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"transaction_filter.status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"transaction_filter.payment_method_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"transaction_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"sale_filter.type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"sale_filter.status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"sale_filter.payment_method_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"sale_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"refund_filter.status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"refund_filter.type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"refund_filter.payment_method_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"refund_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"deposit_filter.settlement_status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"deposit_filter.deposit_status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"deposit_filter.deposit_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"deposit_filter.deposit_sub_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"deposit_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"dispute_filter.transaction_id": {
			"allow_blank": true
		},
		"dispute_filter.type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"dispute_filter.status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"dispute_filter.payment_method_type": {
			"allow_blank": true,
			"childValidations": {}
		},
		"dispute_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		},
		"authorization_filter.sort_by": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"ID"
				]
			}
		},
		"authorization_filter.sort": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"DESC",
					"ASC"
				]
			}
		},
		"authorization_filter.status": {
			"allow_blank": true,
			"childValidations": {}
		},
		"authorization_filter.requested_from": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 50
			},
			"allow_blank": true
		}
	},
	"submitComplianceFormAttestation": {
		"name": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 100
			},
			"allow_blank": true
		},
		"ip_address": {
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
			},
			"allow_blank": true
		},
		"signed_at": {
			"format": {
				"message": "validation_error.wrong_format",
				"with": "^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$"
			},
			"allow_blank": true
		},
		"user_agent": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 200
			},
			"allow_blank": true
		},
		"title": {
			"length": {
				"messages": {
					"max": "validation_error.string.length.max"
				},
				"maximum": 200
			},
			"allow_blank": true
		},
		"is_accepted": {
			"allow_blank": true
		}
	},
	"generateReport": {
		"report_type": {
			"presence": {
				"message": "validation_error.presence"
			},
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"CONSOLIDATED"
				]
			}
		},
		"merchant_id": {
			"allow_blank": true
		},
		"shard_id": {
			"allow_blank": true,
			"enum": {
				"message": "validation_error.enum.invalid",
				"values": [
					"SHARD1"
				]
			}
		},
		"regenerate": {
			"allow_blank": true
		}
	},
	"settleTransactions": {
		"transaction_id": {
			"allow_blank": true
		}
	}
};
export default Object.freeze(validators);