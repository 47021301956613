/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {
    formatPhoneNumber,
    formatZip,
    msToyyyyMMdd,
    unFormatPhoneNumber,
    unHyphenify
} from "@/app/utils/common/functions/functions";
import {validateAndGenerateError} from "@/store/validationFromStore";
import {AppDefaults} from "@/app/utils/common/constants"
import validations from "@/app/validators/api/openapi"
import i18n from '@/i18n'
import {validateField} from "@/app/utils/common/functions/validation";
import Vue from "vue";
import {MerchantRequestDto, MerchantResponseDto} from "@/api/paymentApi";
import FlattenUtil from 'flat';
import {copyFromApiResponse} from "@/store/storeMappers";
import cloneDeep from 'lodash.clonedeep';
import {ApiData, ApiField, OnboardingSectionWithArrayData} from "@/store/modules/onboarding/onboardingTypes";

const Keys = {
    ownership: 'ownership',
}

const invalidOwnershipError = {
    key: Keys.ownership,
    errors: i18n.t('errors.validation_error.controlPersonExpected'),
};

const invalidOwnerTypeError = {key: 'ownerType', errors: i18n.t('errors.validation_error.presence')}
const invalidBusinessName = {key: 'businessName', errors: i18n.t('errors.validation_error.presence')}

const ownershipPercentageError = (maxAllowedNumber: number) => ({
    key: 'ownershipPercentage',
    errors: i18n.t('errors.validation_error.sumOfPercentagesExceedsHundred', {num: maxAllowedNumber}),
});

export const defaultControlPerson: ApiData = {
    firstName: {apiPath: "first_name", value: null, valid: false},
    lastName: {apiPath: "last_name", value: null, valid: false},
    jobTitle: {apiPath: "title", value: null, valid: false},
    dateOfBirth: {
        apiPath: "dob",
        value: null,
        valid: false,
    },
    emailAddress: {apiPath: "email", value: null, valid: false},
    phoneNumber: {
        apiPath: "phone",
        value: null,
        formattedValue: undefined,
        valid: false,
    },
    addressLine1: {apiPath: "personal_address.line1", value: null, valid: false},
    addressLine2: {apiPath: "personal_address.line2", value: null, valid: true},
    addressCity: {apiPath: "personal_address.city", value: null, valid: false},
    addressState: {apiPath: "personal_address.state_code", value: null, valid: false},
    addressZip: {apiPath: "personal_address.postal_code", value: null, valid: false},
    addressCountry: {apiPath: "personal_address.country", value: AppDefaults.country, valid: false},
    ownershipPercentage: {apiPath: "principal_percentage_ownership", value: null, valid: false},
    taxId: {apiPath: "tax_info.tax_id", value: null, valid: false},
    registrationNumber: {apiPath: "tax_info.registration_number", value: null, valid: true},
    vatNumber: {apiPath: "tax_info.vat_number", value: null, valid: true},
}
export const defaultBeneficiaryDetails = (): ApiData => ({
    ownerType: {
        apiPath: "additional_beneficiaries.*.business_owner_type",
        value: null,
        valid: false,
    },
    businessName: {
        apiPath: "additional_beneficiaries.*.business_name",
        value: null,
        valid: false,
    },
    id: {apiPath: "additional_beneficiaries.*.id", value: null, valid: true},
    idAtPayfac: {apiPath: "additional_beneficiaries.*.id_at_payfac", value: null, valid: true},
    firstName: {apiPath: "additional_beneficiaries.*.first_name", value: null, valid: false},
    lastName: {apiPath: "additional_beneficiaries.*.last_name", value: null, valid: false},
    jobTitle: {apiPath: "additional_beneficiaries.*.title", value: null, valid: false},
    dateOfBirth: {
        apiPath: "additional_beneficiaries.*.dob",
        value: null,
        valid: false,
    },
    emailAddress: {apiPath: "additional_beneficiaries.*.email", value: null, valid: false},
    phoneNumber: {
        apiPath: "additional_beneficiaries.*.phone",
        value: null,
        formattedValue: null,
        valid: false
    },
    addressLine1: {apiPath: "additional_beneficiaries.*.personal_address.line1", value: null, valid: false},
    addressLine2: {apiPath: "additional_beneficiaries.*.personal_address.line2", value: null, valid: true},
    addressCity: {apiPath: "additional_beneficiaries.*.personal_address.city", value: null, valid: false},
    addressState: {apiPath: "additional_beneficiaries.*.personal_address.state_code", value: null, valid: false},
    addressZip: {apiPath: "additional_beneficiaries.*.personal_address.postal_code", value: null, formattedValue: null, valid: false},
    addressCountry: {apiPath: "additional_beneficiaries.*.personal_address.country", value: AppDefaults.country, valid: false},
    ownershipPercentage: {apiPath: "additional_beneficiaries.*.principal_percentage_ownership", value: null, valid: false},
    taxId: {apiPath: "additional_beneficiaries.*.tax_info.tax_id", value: null, valid: false},
    registrationNumber: {apiPath: "additional_beneficiaries.*.tax_info.registration_number", value: null, valid: true},
    vatNumber: {apiPath: "additional_beneficiaries.*.tax_info.vat_number", value: null, valid: true},
});

export const stakeholderDetailsDefaultState: OnboardingSectionWithArrayData = {
    api: "createMerchant",
    errors: {},
    data: [cloneDeep(defaultControlPerson)],
};

const businessProfileGetters = {
    getControlPerson: s => s.data[0],
    isControlPersonValid: s => Object.keys(s.data[0]).every(key => s.data[0][key].valid),
    getControlPersonName: s => `${s.data[0].firstName.value} ${s.data[0].lastName.value}`,
    getControlPhoneNumber: s => `${s.data[0].phoneNumber.value}`,
    getBeneficiaries: s => s.data,
    getAdditionalBeneficiaries: s => s.data.slice(1),
    getApi: s => s.api,
    getErrors: s => s.errors,
    getOwnershipErrors: s => s.errors.ownership,
    getBeneficialOwnerType: s => (i) => s.data[i]?.ownerType,
    getBusinessName: s => (i) => s.data[i]?.businessName,
    getId: s => (i) => s.data[i]?.id,
    getFirstName: s => (i) => s.data[i]?.firstName,
    getLastName: s => (i) => s.data[i]?.lastName,
    getJobTitle: s => (i) => s.data[i]?.jobTitle,
    getDateOfBirth: s => (i) => s.data[i]?.dateOfBirth,
    getEmailAddress: s => (i) => s.data[i]?.emailAddress,
    getPhoneNumber: s => (i) => s.data[i]?.phoneNumber,
    getAddressLine1: s => (i) => s.data[i]?.addressLine1,
    getAddressLine2: s => (i) => s.data[i]?.addressLine2,
    getAddressCity: s => (i) => s.data[i]?.addressCity,
    getAddressState: s => (i) => s.data[i]?.addressState,
    getAddressZip: s => (i) => s.data[i]?.addressZip,
    getAddressCountry: s => (i) => s.data[i]?.addressCountry,
    getOwnershipPercentage: s => (i) => s.data[i]?.ownershipPercentage,
    getTaxId: s => (i) => s.data[i]?.taxId,
    isBeneficiaryValid: s => (i) => {
        return Object.keys(s.data[i])
            .reduce((agg, key) => agg && s.data[i][key].valid, true);
    },
    getCurrentSumOfOwnershipPercentages: (state) => {
        return state.data.reduce((sum, a) => {
            sum += parseInt(a.ownershipPercentage.value) || 0;
            return sum;
        }, 0)
    },
    canAddMoreBeneficiaries: (state, getters) => {
        if (state.data.length >= AppDefaults.maxNumberOfBeneficiaries) {
            return false;
        }

        if (getters.getCurrentSumOfOwnershipPercentages === 100) {
            return false;
        }

        const data = defaultBeneficiaryDetails();
        const minPossibleByApiValidation = validations[state.api][data.ownershipPercentage.apiPath].range.minimum;
        const minPossibleByOwnershipType = additionalOwnerPercentageOwnership.min;
        const minPossible = Math.max(minPossibleByApiValidation, minPossibleByOwnershipType);

        return (100 - getters.getCurrentSumOfOwnershipPercentages) >= minPossible;
    },

    getNumberOfValidStackholders: (state, getters) =>
        state.data.filter((_x, i) => getters.isBeneficiaryValid(i)).length,

    isValid: (_state, getters) => {
        const atleastOneBeneficiaryPresent = getters.getBeneficiaries.length > 0;
        const allBeneficiariesValid = getters.getBeneficiaries.every((_x, i) => getters.isBeneficiaryValid(i));
        const sumOfPercentagesIsLessThanHundred = getters.getCurrentSumOfOwnershipPercentages <= 100;
        return atleastOneBeneficiaryPresent && allBeneficiariesValid && sumOfPercentagesIsLessThanHundred;
    },
}

const validateApiField = (commit, api, data, index, key) => {
    const validationSpec = validations[api][data[index][key].apiPath];
    const errors = validateField(data[index][key].value, validationSpec);
    const action = errors.length ? 'setInvalid' : 'setValid';
    commit(action, {index, key})
}

export const controlPersonPercentageOwnership = {
    min: 0,
    max: 100,
}

export const additionalOwnerPercentageOwnership = {
    min: 25,
    max: 100,
}

function between(x, min, max) {
    return parseInt(x) >= min && x <= max;
}

function validateOwnershipPercentage(api: string, data: any[], i, isControlPerson: boolean) {
    const validationSpec = validations[api][data[i].ownershipPercentage.apiPath];
    const value = data[i].ownershipPercentage.value;
    const errors = validateField(value, validationSpec);
    if (errors.length) {
        return errors
    }
    if (isControlPerson && !between(value, controlPersonPercentageOwnership.min, controlPersonPercentageOwnership.max)) {
        return i18n.t('errors.validation_error.controlPersonOwnershipPercentage')
    }

    if (!isControlPerson && !between(value, additionalOwnerPercentageOwnership.min, additionalOwnerPercentageOwnership.max)) {
        return i18n.t('errors.validation_error.additionalBeneficiariesOwnershipPercentage')
    }
}

const validateBusinessOwnerType = (data: any,isControlPerson) => {
    return isControlPerson || data.ownerType.value !== null
}

const validateBusinessName = (data: any,isControlPerson) => {
    return isControlPerson || data.ownerType.value !== 'ENTITY' || data.businessName.value !== null
}

const actions = {
    validateControlPersonPresent({commit, getters}) {
        const controlPersonPresent = getters.isControlPersonValid;
        if (!controlPersonPresent) {
            commit('setErrors', invalidOwnershipError)
        }
        return controlPersonPresent
    },
    validate({commit, state, rootGetters}, i) {
        commit('resetErrors')
        const data = state.data[i];
        const isControlPerson = i === 0
        const validationResult = validateAndGenerateError(commit, stakeholderDetailsDefaultState.api, rootGetters["businessAddress/getCountry"], data);
        const ownershipErrorMessage = validateOwnershipPercentage(stakeholderDetailsDefaultState.api, state.data, i, isControlPerson)
        const ownerTypeValidation = validateBusinessOwnerType(data, isControlPerson);
        const businessNameValidation = validateBusinessName(data, isControlPerson);

        if (ownershipErrorMessage) {
            commit('setErrors', {key: 'ownershipPercentage', errors: ownershipErrorMessage})
        }

        if (!ownerTypeValidation) {
            commit('setErrors', invalidOwnerTypeError)
        }

        if (!businessNameValidation) {
            commit('setErrors', invalidBusinessName)
        }

        return validationResult && businessNameValidation && ownerTypeValidation && !ownershipErrorMessage
    },
    validateField({commit, state}, {index, key}) {
        const data = state.data[index];
        const isControlPerson = index === 0;
        switch (key) {
            case "ownershipPercentage": {
                if (validateOwnershipPercentage(stakeholderDetailsDefaultState.api,
                    state.data,
                    index,
                    isControlPerson)) {
                    return false;
                }
                break;
            }
            case "ownerType": {
                if (!validateBusinessOwnerType(data, isControlPerson)) {
                    return false;
                }
                break;
            }
            case "businessName": {
                if (!validateBusinessName(data, isControlPerson)) {
                    return false;
                }
            }
        }
        validateApiField(commit, stakeholderDetailsDefaultState.api, state.data, index, key)
    },
    validateAllFields({state, dispatch}) {
        state.data.forEach((data, index) => {
            Object.keys(data).forEach(key => dispatch('validateField', {index, key}))
        })
    },
    setErrors({commit}, {i, event}) {
        commit('setErrors', {i, value: event.target.value})
    },
    createStackholder({commit, dispatch, state}) {
        commit('createStackholder')
        dispatch('validateAllFields')
        return state.data.length - 1;
    },
    clearData({commit}) {
        commit('clearData')
    },
    clearErrors({commit}) {
        commit('clearErrors')
    },
    deleteStackholder({commit}, i) {
        commit('deleteStackholder', i)
    },
    resetControlPerson({commit}) {
        commit('resetControlPerson')
    },
    setOwnerType({commit, dispatch}, {i, value}) {
        commit('setOwnerType', {i, value})
        dispatch('validateField', {index: i, key: 'ownerType'})
    },
    setBusinessName({commit, dispatch}, {i, event}) {
        commit('setBusinessName', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'businessName'})
    },
    setFirstName({commit, dispatch}, {i, event}) {
        commit('setFirstName', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'firstName'})
    },
    setLastName({commit, dispatch}, {i, event}) {
        commit('setLastName', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'lastName'})
    },
    setJobTitle({commit, dispatch}, {i, event}) {
        commit('setJobTitle', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'jobTitle'})
    },
    setDateOfBirth({commit, dispatch}, {i, value}) {
        commit('setDateOfBirth', {i, value})
        dispatch('validateField', {index: i, key: 'dateOfBirth'})
    },
    setEmailAddress({commit, dispatch}, {i, event}) {
        commit('setEmailAddress', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'emailAddress'})
    },
    setPhoneNumber({commit, dispatch}, {i, value, countryCode}) {
        commit('setPhoneNumber', {i, value, countryCode})
        dispatch('validateField', {index: i, key: 'phoneNumber'})
    },
    setAddressLine1({commit, dispatch}, {i, event}) {
        commit('setAddressLine1', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'addressLine1'})
    },
    setAddressLine2({commit}, {i, event}) {
        commit('setAddressLine2', {i, value: event.target.value})
    },
    setAddressCity({commit, dispatch}, {i, event}) {
        commit('setAddressCity', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'addressCity'})
    },
    setAddressState({commit, dispatch}, {i, value}) {
        commit('setAddressState', {i, value})
        dispatch('validateField', {index: i, key: 'addressState'})
    },
    setAddressZip({commit, dispatch}, {i, event}) {
        commit('setAddressZip', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'addressZip'})
    },
    setAddressCountry({commit, dispatch}, {i, value}) {
        commit('setAddressCountry', {i, value})
        dispatch('validateField', {index: i, key: 'addressCountry'})
    },
    setOwnershipPercentage({commit, state, dispatch}, {i, event}) {
        const value = event.target.value;
        commit('setOwnershipPercentage', {i, value: value})
        let sumOfPercentagesOfOtherStackholders = 0;
        for (let j = 0; j < state.data.length; j++) {
            if (j === i) continue;
            sumOfPercentagesOfOtherStackholders += parseInt(state.data[j].ownershipPercentage.value) || 0;
        }
        const maxAllowedNumber = 100 - sumOfPercentagesOfOtherStackholders;
        if (parseInt(value) > maxAllowedNumber) {
            commit('setErrors', ownershipPercentageError(maxAllowedNumber))
            commit('setInvalid', {index: i, key: 'ownershipPercentage'})
        } else {
            commit('resetErrorByKey', 'ownershipPercentage')
            dispatch('validateField', {index: i, key: 'ownershipPercentage'})
        }
    },
    setTaxId({commit, dispatch}, {i, event}) {
        commit('setTaxId', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'taxId'})
    },
    setRegistrationNumber({commit, dispatch}, {i, event}) {
        commit('setRegistrationNumber', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'taxId'})
    },
    setVatNumber({commit, dispatch}, {i, event}) {
        commit('setVatNumber', {i, value: event.target.value})
        dispatch('validateField', {index: i, key: 'taxId'})
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('clearData')
        commit('copyControlOwnerFromMerchantApiResponse', merchantApiResponse)
        commit('copyAdditionalBeneficiariesFromMerchantApiResponse', merchantApiResponse)
    },
};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    resetErrorByKey(state, key): void {
        delete state.errors[key];
    },
    setInvalid(state, {index, key}) {
        state.data[index][key].valid = false;
    },
    setValid(state, {index, key}) {
        state.data[index][key].valid = true;
    },
    setErrors(state, {key, errors}): void {
        Vue.set(state.errors, key, errors);
    },
    createStackholder(state): void {
        state.data.push(defaultBeneficiaryDetails())
        // state.data.push(beneficiariesWithTestValues())
    },
    clearData(state): void {
        Vue.set(state, 'data', [])
        Vue.set(state.data, 0, cloneDeep(defaultControlPerson))
    },
    clearErrors(state): void {
        state.errors = {}
    },
    deleteStackholder(state, i): void {
        state.data.splice(i, 1);
    },
    resetControlPerson(state) : void {
        Vue.set(state.data, 0, cloneDeep(defaultControlPerson))
    },
    setOwnerType(state, {i, value}): void {
        state.data[i].ownerType.value = value;
        delete state.errors.ownerType;
    },
    setBusinessName(state, {i, value}): void {
        state.data[i].businessName.value = value;
        delete state.errors.businessName;
    },
    setFirstName(state, {i, value}): void {
        state.data[i].firstName.value = value;
        delete state.errors.firstName;
    },
    setLastName(state, {i, value}): void {/**/
        state.data[i].lastName.value = value;
        delete state.errors.lastName;
    },
    setJobTitle(state, {i, value}): void {
        state.data[i].jobTitle.value = value;
        delete state.errors.jobTitle;
    },
    setDateOfBirth(state, {i, value}): void {
        state.data[i].dateOfBirth.value = msToyyyyMMdd(value);
        delete state.errors.dateOfBirth;
    },
    setEmailAddress(state, {i, value}): void {
        state.data[i].emailAddress.value = value.trim();
        delete state.errors.emailAddress;
    },
    setPhoneNumber(state, {i, value, countryCode}): void {
        state.data[i].phoneNumber.value = `${countryCode} ${unFormatPhoneNumber(value)}`;
        state.data[i].phoneNumber.formattedValue = value;
        delete state.errors.phoneNumber;
    },
    setAddressLine1(state, {i, value}): void {
        state.data[i].addressLine1.value = value;
        delete state.errors.addressLine1;
    },
    setAddressLine2(state, {i, value}): void {
        state.data[i].addressLine2.value = value;
        delete state.errors.addressLine2;
    },
    setAddressCity(state, {i, value}): void {
        state.data[i].addressCity.value = value;
        delete state.errors.addressCity;
    },
    setAddressState(state, {i, value}): void {
        state.data[i].addressState.value = value;
        delete state.errors.addressState;
    },
    setAddressZip(state, {i, value}): void {
        state.data[i].addressZip.value = unHyphenify(value);
        delete state.errors.addressZip;
    },
    setAddressCountry(state, {i, value}): void {
        state.data[i].addressCountry.value = value;
        delete state.errors.addressCountry;
    },
    setOwnershipPercentage(state, {i, value}): void {
        state.data[i].ownershipPercentage.value = value;
        delete state.errors.ownershipPercentage;
    },
    setTaxId(state, {i, value}): void {
        state.data[i].taxId.value = unHyphenify(value);
        delete state.errors.taxId;
    },
    setRegistrationNumber(state, {i, value}): void {
        state.data[i].registrationNumber.value = unHyphenify(value);
        delete state.errors.taxId;
    },
    setVatNumber(state, {i, value}): void {
        state.data[i].vatNumber.value = unHyphenify(value);
        delete state.errors.taxId;
    },
    copyControlOwnerFromMerchantApiResponse(state, merchantApiResponse: MerchantResponseDto): void {
        copyControlPersonFromMerchantApiResponse(merchantApiResponse, state.data[0]);
    },
    copyAdditionalBeneficiariesFromMerchantApiResponse(state, merchantApiResponse: MerchantResponseDto): void {
        merchantApiResponse.additional_beneficiaries?.forEach((_, index) => {
            const newOwner = copyAdditionalBeneficiaryFromMerchantApiResponse(merchantApiResponse, index);
            state.data.push(newOwner);
        })
    },
};

function copyControlPersonFromMerchantApiResponse(apiResponse: MerchantRequestDto, controlPersonData: any) {
    const newOwner = copyFromApiResponse(apiResponse, controlPersonData);
    const {formattedValue} = parsePhoneNumber(newOwner.phoneNumber);
    newOwner.phoneNumber = {...newOwner.phoneNumber, formattedValue};
    (newOwner.addressZip as ApiField).formattedValue = formatZip(newOwner.addressZip.value);
    return newOwner;
}

function copyAdditionalBeneficiaryFromMerchantApiResponse(apiResponse: MerchantRequestDto, index: number) {
    const newOwner = defaultBeneficiaryDetails()
    const flatApiResponse = FlattenUtil(apiResponse);
    Object.keys(newOwner)
        .forEach(key => {
            const indexedApiPath = newOwner[key].apiPath.replace("*", String(index));
            return newOwner[key].value = flatApiResponse[indexedApiPath];
        })
    const {formattedValue} = parsePhoneNumber(newOwner.phoneNumber);
    newOwner.phoneNumber = {...newOwner.phoneNumber, formattedValue}
    newOwner.addressZip.formattedValue = formatZip(newOwner.addressZip.value);
    return newOwner;
}

/**
 *
 * @param phoneNumberData format: "country_code phone_number" or "phone_number"
 */
const parsePhoneNumber = (phoneNumberData) => {
    const [countryCode, phoneNumber] = phoneNumberData.value?.split(" ") || [];
    phoneNumberData.countryCode = countryCode || AppDefaults.countryCode;
    const phoneNumberWithCountryCode = phoneNumber || phoneNumberData.value;
    const formattedValue = formatPhoneNumber(phoneNumberWithCountryCode)
    return {formattedValue, countryCode: countryCode || phoneNumberData.countryCode}
}

export const stakeholdersDetails = {
    namespaced: true,
    getters: businessProfileGetters,
    state: stakeholderDetailsDefaultState,
    actions,
    mutations,
};