import FlattenUtil from 'flat';
import Vue from "vue";
import {ApiData, ApiField} from "@/store/modules/onboarding/onboardingTypes";

export const copyFromErrorResponse = (subErrors, data) => {
    const errors = {}
    Object.keys(data).forEach(key => {
        const matchingErrors = subErrors?.filter(error => error.field === data[key].apiPath) || [];
        if (matchingErrors.length > 0) {
            Vue.set(errors, key, matchingErrors[0].message)
        }
    })
    return errors
}

export const copyFromApiResponse = (apiResponse: any, data: ApiData) => {
    const flatApiResponse = FlattenUtil(apiResponse);
    Object.keys(data)
        .filter(key => !(data[key] as ApiField).default)
        .forEach(key => {
            const apiField = data[key] as ApiField;
            const value = flatApiResponse[apiField.apiPath];
            if (value === undefined) return data;
            if (apiField.setValue) {
                apiField.setValue(value)
            } else {
                apiField.value = value;
            }
        })
    return data
};

export const storeToApiRequest = (data: ApiData) => {
    if (data === undefined) return data;

    if (Array.isArray(data)) {
        const final = data.reduce((result, x, i) => {
            return Object.keys(x)
                .reduce((obj, key) => {
                    if (key === 'id' || key === 'idAtPayfac') {
                        return ({...obj})
                    }
                    const value = x[key].getValue ? x[key].getValue() : x[key].value;
                    return ({...obj, [x[key].apiPath.replace("*", i)]: value});
                }, result);
        }, {})
        return FlattenUtil.unflatten(final);
    }

    const objWithApiPath = Object.keys(data)
        .reduce((obj, key) => {
            const apiField = data[key] as ApiField;
            const value = apiField.getValue ? apiField.getValue() : apiField.value;
            return ({...obj, [apiField.apiPath]: value});
        }, {});
    return FlattenUtil.unflatten(objWithApiPath);
}
