<template>
  <div class="dynamic-terms-container">
    <div class="processing-rate">
      <div class="sub-header">
        {{ $t('termsAndCharges.processingRate') }}
      </div>
      <div class="charges">
        <div class="charge-item">
          <span class="bold">{{ cardTransactionFee }}</span> {{ $t('termsAndCharges.perCreditCardTransfer') }}
        </div>
        <div v-if="hasBankTransferTransactionFee('ACH_DEBIT')" class="charge-item">
          <span class="bold"> {{ bankTransferTransactionFee('ACH_DEBIT') }} </span> {{ $t('termsAndCharges.perTransfer.ACH_DEBIT') }}
        </div>
        <div v-if="hasBankTransferTransactionFee('SEPA_DEBIT')" class="charge-item">
          <span class="bold"> {{ bankTransferTransactionFee('SEPA_DEBIT') }} </span> {{ $t('termsAndCharges.perTransfer.SEPA_DEBIT') }}
        </div>
        <div v-if="hasBankTransferTransactionFee('BACS_DEBIT')" class="charge-item">
          <span class="bold"> {{ bankTransferTransactionFee('BACS_DEBIT') }} </span> {{ $t('termsAndCharges.perTransfer.BACS_DEBIT') }}
        </div>
      </div>
      <i18n path="termsAndCharges.processingRateInstruction" tag="div">
        <template #feeSchedule>
          <c-link :href="feeScheduleUrl" target="_blank">
            {{ $t('termsAndCharges.feeSchedule') }}
          </c-link>
        </template>
      </i18n>
    </div>
    <div class="terms-and-conditions">
      <div class="sub-header-container">
        <div class="sub-header">
          {{ $t('termsAndCharges.termsAndConditions') }}
        </div>
        <div>
          {{ $t('termsAndCharges.termsAndConditionsInstruction') }}
        </div>
      </div>
      <div class="terms-to-accept">
        <label class="container">
          {{ $t('termsAndCharges.acceptPrompt') }}
          <ul>
            <i18n path="termsAndCharges.guidelinesConditionPayfac" tag="li">
              <template #chargebeeTerms>
                <c-link href="https://www.chargebee.com/embedded-payments-tou/" target="_blank">
                  {{ $t('termsAndCharges.chargebeeTerms') }}
                </c-link>
              </template>
              <template #payfacTerms>
                <div style="display: inline-block;">
                  {{ $t('termsAndCharges.joinTermsOfService') }}
                </div>
                <c-link href="https://www.chargebee.com/anv-payments-tos/" target="_blank">
                  {{ $t('termsAndCharges.adyenTerms') }}
                </c-link>
              </template>
            </i18n>
            <li>
              {{ $t('termsAndCharges.guidelinesCondition') }}
            </li>
            <i18n path="termsAndCharges.saqaCondition" tag="li">
              <template #pciDssRequirements>
                <c-link href="https://www.chargebee.com/anv-pci-dss-saq/" target="_blank">
                  {{ $t('termsAndCharges.pciDssRequirements') }}
                </c-link>
              </template>
            </i18n>
          </ul>
          <input type="checkbox" @click="(event) => acceptAgreement(event.target.checked)" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="buttons">
        <c-button
          @click="closePopup"
          :disabled="loading"
          size="large"
        >
          {{ $t('global.action.cancel') }}
        </c-button>
        <c-button
          @click="onboardMerchant"
          :loading="loading"
          :disabled="!getSubMerchantAgreementAcceptance"
          variant="primary"
          size="large"
        >
          {{ $t('global.action.iAccept') }}
        </c-button>
      </div>
    </div>
  </div>
</template>
<script>


import {mapActions, mapGetters} from "vuex";
import { BankTransferType, Country } from "@/app/utils/common/constants";

export default {
  name: "AdyenTermsAndConditions",
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    onboardMerchant: {
      type: Function,
      required: true
    },
    closePopup: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    ...mapGetters('agreementAcceptance', ['getSubMerchantAgreementAcceptance']),
    ...mapGetters('app', ['getCardPricing', 'getACHPricing', 'getBACSPricing', 'getSEPAPricing']),
    ...mapGetters('bankAccountForPayouts', ['getBankTransferType']),
    ...mapGetters('businessAddress', ['getCountry']),
    cardTransactionFee() {
      return this.getCardPricing.basis_points/100+"% + " + this.getCurrency() +(this.getCardPricing.fixed_rate/100).toFixed(2);
    },
    feeScheduleUrl() {
      const merchantCountry = this.getCountry;

      switch (merchantCountry) {
        case Country.GBR:
          return 'https://www.chargebee.com/gb/embedded-payments-fs';
        case Country.FRA:
          return 'https://www.chargebee.com/fr/embedded-payments-fs';
        case Country.DEU:
          return 'https://www.chargebee.com/de/embedded-payments-fs';
        case Country.ESP:
          return 'https://www.chargebee.com/es/embedded-payments-fs';
        default:
          return 'https://www.chargebee.com/embedded-payments-fs/';
      }
    }
  },
  methods: {
    ...mapActions('agreementAcceptance', ['acceptAgreement']),
    hasBankTransferTransactionFee(type) {
      let pricing;

      if (type === BankTransferType.ACH_DEBIT) {
        pricing = this.getACHPricing;
      } else if (type === BankTransferType.SEPA_DEBIT) {
        pricing = this.getSEPAPricing;
      } else if (type === BankTransferType.BACS_DEBIT) {
        pricing = this.getBACSPricing;
      }

      if (!pricing) {
        return false;
      }

      // Check if any of the relevant fields exist and are greater than 0
      return (
          (pricing.basis_points && pricing.basis_points > 0) ||
          (pricing.fixed_rate && pricing.fixed_rate > 0) ||
          (pricing.min_fee && pricing.min_fee > 0) ||
          (pricing.max_fee && pricing.max_fee > 0)
      );
    },
    getCurrency() {
      if(this.getBankTransferType === BankTransferType.ACH_DEBIT) {
        return '$';
      } else if (this.getBankTransferType === BankTransferType.SEPA_DEBIT) {
        return '€';
      } else {
        return '£';
      }
    },
    // Retrieve the correct pricing based on the transfer type
    getPricingByType(type) {
      if (type === BankTransferType.ACH_DEBIT) {
        return this.getACHPricing;
      } else if (type === BankTransferType.SEPA_DEBIT) {
        return this.getSEPAPricing;
      } else if (type === BankTransferType.BACS_DEBIT) {
        return this.getBACSPricing;
      }
    },

    // Calculate the bank transfer fee based on pricing
    bankTransferTransactionFee(type) {
      const pricing = this.getPricingByType(type);
      return this.bankTransferFee(pricing);
    },
    // Compute the bank transfer fee structure
    bankTransferFee(bankTransferPricing) {
      if (!bankTransferPricing) {
        throw new Error("Invalid configuration. Please contact support.");
      }

      const components = [];

      // Add basis points if greater than 0
      if (bankTransferPricing.basis_points > 0) {
        components.push((bankTransferPricing.basis_points / 100) + "%");
      }

      // Add fixed rate if greater than 0
      if (bankTransferPricing.fixed_rate > 0) {
        if (components.length > 0) {
          components.push("+ " );
        }
        components.push(this.getCurrency() + (bankTransferPricing.fixed_rate / 100).toFixed(2));
      }

      // Prepare min/max fees if either is greater than 0
      let feeRange = "";
      if (bankTransferPricing.min_fee > 0) {
        feeRange = this.getCurrency() + (bankTransferPricing.min_fee / 100).toFixed(2) + " minimum";
      }
      if (bankTransferPricing.max_fee > 0) {
        feeRange += (feeRange ? ", " : "") + this.getCurrency() + (bankTransferPricing.max_fee / 100).toFixed(2) + " maximum";
      }

      // Add the fee range if min or max fees are present
      if (feeRange) {
        components.push("(" + feeRange + ")");
      }

      // If both components exist, do not use " + " between them
      return components.join(" ");
    }
  }
}
</script>

<style lang="scss">


.dynamic-terms-container {

  & .buttons {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: end;
    gap: 20px;
    font-weight: $weight_bold;
    background-color: $color-white;

    & button {
      margin-top: 40px;
      margin-bottom: 33px;
    }
  }

  & .processing-rate {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 26px;

    & .sub-header {
      margin-bottom: 8px;
      color: $text-color;
    }

    & .charges {
      margin-top: 20px;

      & .charge-item {
        margin-bottom: 8px;
        margin-top: 12px;

      }
    }
  }

  & .sub-header {
    margin-bottom: 8px;
    color: $text-color;
    margin-top: 25px;
    font-size: 16px;
    font-weight: $weight_bolder;
  }

  & .sub-header-container {
    margin-bottom: 20px;
  }

  & .terms-and-conditions {
    text-align: left;
    margin-top: 40px;

    & .conditions {
      margin-left: 50px;

      & li {
        line-height: 1.5;
        margin: 10px;
      }
    }

    & .charges {

      & .charge-item {
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
  }

  & .terms-to-accept {

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-top: 30px;
      margin-bottom: 12px;
      cursor: pointer;
      user-select: none;
      line-height: 1.4;

      & ul {
        padding-left: 20px;
      }
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $disablea_text;
      border-radius: 4px;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $primary_fill;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      left: 6px;
      top: 3px;
      width: 6px;
      height: 11px;
    }
  }

}


</style>